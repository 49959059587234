import React from "react"
import SEO from "../components/seo"
import quoteStyles from "../styles/quote.module.scss"
import Layout from "../components/layout"


const Quote = ((props) => {
  return (
    <Layout>
      <SEO title="Quote"/>
      <div className={quoteStyles.maindiv}>
        {/* left div (service info) */}
        <div className={quoteStyles.servinfodiv}>
          <div className={quoteStyles.textdiv}>
            <div className={quoteStyles.contacthead}>OR REACH US</div>
            <br/>
            {/* <div>icon</div> */}
            <div>EMAIL: bookings@ladyonwheelsservices.co.za</div>
            <div className={quoteStyles.textline}/>
            {/* <div>icon</div> */}
            <div>TEL:</div>
            <div>Johannesburg: 010 446 0223</div>
            <div>Rustenburg: 014 880 0610</div>
            <div className={quoteStyles.textline}/>
            {/* <div>icon</div> */}
            <div>Whatsapp: 062 192 8685</div>
          </div>
        </div>
        {/* right div (quote) */}
        <div className={quoteStyles.quotediv}>
          <div className={quoteStyles.contacthead2}>OUR SERVICES</div>
          <br/>
          <div><span>&emsp; &emsp;</span>Domestic Cleaning</div>
          <div><span>&emsp; &emsp;</span>Commercial Cleaning</div>
          <div><span>&emsp; &emsp;</span>Overnight Babysitting</div>
          <div><span>&emsp; &emsp;</span>Mobile Car Wash</div>
          <div><span>&emsp; &emsp;</span>Post Construction/Residential</div>
          <div><span>&emsp; &emsp;</span>End of Tenancy Service</div>
          <div><span>&emsp; &emsp;</span>Carpet, Matress and Coach</div>
          <div><span>&emsp; &emsp;</span>Maid Training Service</div>
          {/* <h3>Get A Quote</h3>
          <form className={quoteStyles.formdiv}>
              <div className={quoteStyles.namediv}>
                <div className={quoteStyles.firstnamelabel}><label for="firstname">First Name*</label></div>
                <div className={quoteStyles.firstnameinput}><input style={{width:'100%',border:'solid 2px black',paddingLeft:'4px'}} type="text" id="firstname" name="firstname" placeholder="your first name..."></input></div>
                <div className={quoteStyles.lastnamelabel}><label for="lastname">Last Name*</label></div>
                <div className={quoteStyles.lastnameinput}><input style={{width:'100%',border:'solid 2px black',paddingLeft:'4px'}} type="text" id="lastname" name="lastname" placeholder="your last name..."></input></div>
              </div>
              <div className={quoteStyles.contactdiv}>
                <div className={quoteStyles.tellabel}><label for="telnumber">Contact Number*</label></div>
                <div className={quoteStyles.telinput}><input style={{width:'100%',border:'solid 2px black',paddingLeft:'4px'}} type="number" id="telnumber" name="telnumber" placeholder="your contact number..."></input></div>
                <div className={quoteStyles.emaillabel}><label for="form-email">Email*</label></div>
                <div className={quoteStyles.emailinput}><input style={{width:'100%',border:'solid 2px black',paddingLeft:'4px'}} type="email" id="form-email" placeholder="your email address..."/></div>
              </div>
              
              <div className={quoteStyles.addressdiv}>
                <div className={quoteStyles.addresslabel}><label for="form-address">Address of Property to be Cleaned*</label></div>
                <div className={quoteStyles.addressinput}><input style={{width:'100%',border:'solid 2px black'}} type="text" id="form-address" placeholder=" your address..."/></div>
              </div>

              <div className={quoteStyles.selectdiv}>
                <div className={quoteStyles.selectlabel1}><label for="form-service">Select Cleaning Service*</label></div>
                <div className={quoteStyles.selectoptions1}>
                    <select style={{width:'100%',border:'solid 2px black'}} id="form-service-option">
                        <option style={{color:'white',background:'black'}}>Domestic Cleaning</option>
                        <option style={{color:'white',background:'black'}}>Commercial Cleaning</option>
                        <option style={{color:'white',background:'black'}}>Overnight Baby Sitting</option>
                        <option style={{color:'white',background:'black'}}>Mobile Car Wash</option>
                        <option style={{color:'white',background:'black'}}>Post Construction/Residential</option>
                        <option style={{color:'white',background:'black'}}>End of Tenancy Service</option>
                        <option style={{color:'white',background:'black'}}>Carpet, Matress and Couch</option>
                        <option style={{color:'white',background:'black'}}>Maid Training Service</option>
                    </select>
                </div>
                <div className={quoteStyles.selectlabel2}><label for="form-hearabout">How did you hear about us?*</label></div>
                <div className={quoteStyles.selectoptions2}>
                    <select style={{width:'100%',border:'solid 2px black'}} id="form-hearabout-option">
                        <option style={{color:'white',background:'black'}}>Google Search</option>
                        <option style={{color:'white',background:'black'}}>Housekeeping on Crownwood</option>
                        <option style={{color:'white',background:'black'}}>Word of Mouth</option>
                        <option style={{color:'white',background:'black'}}>Branded Vehicles</option>
                        <option style={{color:'white',background:'black'}}>Leaflets/Flyers</option>
                        <option style={{color:'white',background:'black'}}>Online Directories</option>
                    </select>
                </div>
              </div>

              <div className={quoteStyles.commentdiv}>
                <div className={quoteStyles.commentlabel}><label for="form-address">Comment</label></div>
                <div className={quoteStyles.commentinput}><textarea style={{width:'100%',border:'solid 2px black',paddingLeft:'4px'}} type="text" rows="2" id="form-address" placeholder="leave a comment here..."/></div>
              </div>

              <div className={quoteStyles.formcheckbox}>
                  <label for="formcheckbox" class="form-checkbox">
                  <input type="checkbox" id="formcheckbox" /> I&#x27;ve read the terms and conditions*</label>
              </div>
              <div className={quoteStyles.formbutton}><a type="submit" className={quoteStyles.formsubmitbut}>SUBMIT</a></div>
          </form> */}
        </div>
      </div>
    </Layout>
  )
}
)

export default Quote